import React from 'react';
import axios from 'axios';
import PersonalMenu from './squadPlannerMenu';
import WaiverPlannerLayout from './waiverPlannerLayout';
import { optionGroupClasses } from '@mui/base';

class SquadPlannerLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueID: this.props.leagueID,
      wp_id: this.props.wp_id,
      selectedComponent: WaiverPlannerLayout,
      gw: this.props.gw,
      placeholder: true
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChange);
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({leagueID: this.props.leagueID})
      //this.initializeTable();
    }
  }


  render() {
    const { selectedComponent, leagueID, gw, wp_id, placeholder} = this.state;
    const SelectedComponent = selectedComponent;

    return (
    
            <div>
              <PersonalMenu onChange={this.onChange.bind(this)} />
              <div className={SelectedComponent.name == 'FDRLayout' ? "" : "p-20 personal"}>
                {SelectedComponent && <SelectedComponent leagueID={leagueID} wp_id={wp_id} gw={gw} />} {/* Render the selected component here */}
              </div>
            </div> 
    );
  }
}

export default SquadPlannerLayout;
