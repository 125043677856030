import React, { useMemo } from 'react';
import axios from 'axios';
import teamImages from '../../images/shirts/shirtsLookup.js';
import Shield from '../../images/logo_shield.png';
import Pitch from '../../images/pitch.png';

class SuggestedWaiversLayout extends React.Component {
  constructor(props) {
    super(props);
    //const distinctValues = ['GKP', 'DEF', 'MID', 'FWD']; // add this line to initialize distinctValues
    this.state = {
      leagueID: this.props.leagueID,
      wp_id: this.props.wp_id,
      errorMessage: false,
      isSpecialLeague: false,
      gameweekPlannerData: null,
      currentSelections: null,
      gameweekWaivers:null,
      loading: true,
      scoring: null,
      gw: this.props.gw,
      placeholder: true
    }
  }

  componentDidMount() {
    if (this.state.placeholder != true){
    this.setSpecialLeague(() => 
    this.getOwnershipData(this.props.leagueID, this.state.wp_id, this.state.gw),
    this.getSelectionsData(this.props.leagueID, this.state.wp_id)
    )};


    //this.getData(this.props.leagueID, this.state.wp_id);
    //TableSort()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({globalData: ''}, () => {
        this.setSpecialLeague(() => this.updateContent(this.props.leagueID, this.state.wp_id));
      });
      //this.initializeTable();
    }
  }

  updateContent(prop) {
    this.setState({ leagueID: prop })
    this.getData(prop, this.state.wp_id)
  }

  getOwnershipData(leagueID, wp_id, gw) {
    this.setState({loading: true})
    if (leagueID) {
        axios
        .post('/api/getGameweekOwnerData', { leagueID: leagueID, wp_id: wp_id, gw: gw })
        .then((res) => {
          this.setState({ xPts1: res.data.xPts1, xPts3: res.data.xPts3, xPts5: res.data.xPts5, loading: false }, () => {
            //this.initializeTable(); // initialize the table once the data is loaded
          })
          this.setState({errorMessage: false});
          
        })
        .catch((error) => this.setState({errorMessage: true}));
      }
  }

  getSelectionsData(leagueID, wp_id) {
    this.setState({loading: true})
    if (leagueID) {
        axios
        .post('/api/getGameweekSelectionsData', { leagueID: leagueID, wp_id: wp_id })
        .then((res) => {
          this.setState({ gameweekPlannerData: res.data, loading: false }, () => {
            //this.initializeTable(); // initialize the table once the data is loaded
          })
          this.setState({errorMessage: false});
          
        })
        .catch((error) => this.setState({errorMessage: true}));
      }
  }


  setSpecialLeague(callback) {
    const isSpecialLeague = ['9999996', '9999997', '9999998'].includes(this.props.leagueID);
    this.setState({ isSpecialLeague }, callback);
  }

  getTeamImage = (teamId) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
}

  
  render() {

    const {isSpecialLeague, loading, errorMessage, placeholder} = this.state

    if (placeholder) {
      return (<div>
        <h2>Waiver Suggestions will be replaced by our NEW Gameweek Planner!</h2>
        <p>We have made major upgrades to our prediction model, and are finalising this before launching the Gameweek Planner.</p>
        <p>This will feature a pitch view where you can simulate waivers and trades for every gameweek in the season to see what your team would look like in any scenario.</p>
        <p>This tool will be driven by our upgraded AI powered xPts model to give you the ultimate edge in your league.</p>
        </div>)
    }

    if (loading == true) {
      return (
        <div>
        <h2>Waiver Suggestion Engine</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>
      )
    }

    if (isSpecialLeague) {
      return (<div>
        <h2>Thsi feature is available if you have synced a FPL Draft Team ID</h2>
        <p>Please select a FPL Draft League, or go to 'Add New Leagues' to sync a new league.</p>
        </div>)
    }

    if (errorMessage == true) {
      return (<div><h3>There was an error loading the data. Please refresh the page.</h3></div>)
    }


    return (
      <div> 
        </div>
    );
  }
}

export default SuggestedWaiversLayout;
