import React, { useMemo } from 'react';
import axios from 'axios';

class FDRLayout extends React.Component {
  constructor(props) {
    super(props);
    //const distinctValues = ['GKP', 'DEF', 'MID', 'FWD']; // add this line to initialize distinctValues
    this.state = {
      loading: false,
      errorMessage: false,
      fdr: [],
      viewToggle: 'win',
      beginGW: 1, // Initialize with default values
      endGW: 38,
      placeholder: false
    }
  }

  componentDidMount() {
    if (this.state.placeholder == false) {
    this.getData()}
  }


  getData() {
    this.setState({loading: true})

        axios
        .post('/api/getFDR')
        .then((res) => {
          this.setState({ fdr: res.data.fdr})
          this.setState({errorMessage: false, loading: false});
          
        })
        .catch((error) => this.setState({errorMessage: true}));
      }
  
      getCellDataByTeamAndGW = (team, gw) => {
        const matches = this.state.fdr.filter(item => item.team_short_name === team && item.gw === gw);
        return matches.length > 0 ? matches : null;
    }
    

  getCellClassName = (percentValue) => {
    const min = 0;  // change this to your desired minimum
    let max;

    if (this.state.viewToggle === 'win') {
      max = 70;  // max for 'win'
    } else if (this.state.viewToggle === 'cs') {
      max = 50;  // different max for 'cs'
    } else {
      return '';  // Default or error class if viewToggle doesn't match known values
    }

    const numClasses = 8;
    const intervalSize = (max - min) / numClasses;

    // Find out which interval the percentValue belongs to
    let intervalIndex = Math.ceil((percentValue - min) / intervalSize);

    // Ensure it does not exceed the number of available classes
    intervalIndex = Math.min(intervalIndex, numClasses);

    return `fdr-${intervalIndex}`;
  }

  handleWinClick = () => {
      this.setState({ viewToggle: 'win' });
  }

  handleCsClick = () => {
      this.setState({ viewToggle: 'cs' });
  }

  handleBeginGWChange = (event) => {
    this.setState({ beginGW: parseInt(event.target.value) });
  }

  handleEndGWChange = (event) => {
      this.setState({ endGW: parseInt(event.target.value) });
  }


  handleSortChange = (event) => {
    this.setState({ sortMethod: event.target.value });
  }


  render() {

    const {fdr, loading, errorMessage, beginGW, endGW, placeholder} = this.state
    console.log(fdr)

    const currentPercent = this.state.viewToggle === 'win' ? 'win_percent' : 'cs_percent';

    if (placeholder) {
      return (<div className="p-20">
        <h2>FDR will be ready soon.</h2>
        <p>We have made major upgrades to our prediction model, and are finalising this before launching our new and improved FDR on Monday 29th July.</p>
        </div>)
    }

    if (loading == true) {
      return (
        <div className="p-20">
        <h2>Fixture Difficulty</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>
      )
    }

    if (errorMessage == true) {
      return (<div><h3>There was an error loading the data. Please refresh the page.</h3></div>)
    }

    const distinctTeams = Array.from(new Set(fdr.map(item => item.team_short_name))).sort();

    const distinctGW = Array.from(new Set(fdr.map(item => item.gw))).sort((a, b) => a - b);

    const filteredGW = distinctGW.filter(gw => gw >= beginGW && gw <= endGW);

    return (
      <div>
        <div className="menuWrapper">
        <button  onClick={this.handleWinClick}>Win</button>
        <button onClick={this.handleCsClick}>Clean Sheet</button>
      </div>

      <div className="flex g-10 ml-20 mt-20">
      {/* Begin GW Dropdown */}
      <div className="flex">
        <div className="flex column g-10">
      <label>Start Gameweek</label>
      <select className="w-150 grey-border" value={beginGW} onChange={this.handleBeginGWChange}>
          {distinctGW.filter(gw => gw <= endGW).map(gw => <option key={gw} value={gw}>{gw}</option>)}
      </select>
      </div>
      </div>

      {/* End GW Dropdown */}
      <div className="flex">
        <div className="flex column g-10">
        <label>End Gameweek</label>
      <select className="w-150 grey-border" value={endGW} onChange={this.handleEndGWChange}>
        {distinctGW.filter(gw => gw >= beginGW).map(gw => <option key={gw} value={gw}>{gw}</option>)}
      </select>
      </div>
      </div>
      </div>

      
      <div className="p-20">
                <div className="grey-border personal content-wrapper">
                  <div>
                  <h3>Fixture Difficulty</h3>
                </div>

                <div className="table-wrapper vh-70">
                <table>
                    <thead>
                        <tr>
                            <th>Team</th>
                            {filteredGW.map(gw => (
                                <th className="text-center" key={gw}>GW{gw}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
    {distinctTeams.map(team => (
        <tr key={team} className="fdr-row">
            <td>{team} vs</td>
            {filteredGW.map(gw => {
                const matches = this.getCellDataByTeamAndGW(team, gw);
                return (
                    <td key={gw} className="fdr-cell">
                      <div className="flex">
                        {matches && matches.map((match, index) => (
                            <div key={index} className={`semibold text-center fdr-span flex w-80 ${this.getCellClassName(match[currentPercent])}`}>
                                <div className="cell-block fullwidth">
                                <span>{match.opponent_short_name} ({match.h_a})</span>
                                <span>{(match[currentPercent]).toFixed(0)}%</span>
                                </div>
                            </div>
                            
                        ))}
                        </div>
                    </td>
                );
            })}
        </tr>
    ))}
</tbody>
                </table>
                </div>
                </div>
            </div>
            </div>
    );
  }
}

export default FDRLayout;
